import { __decorate } from "tslib";
import { Vue, Component } from 'vue-property-decorator';
import SyngooFaleConosco from '@/components/SyngooFaleConosco.vue';
import { syngooMensagemConfiguracaoStore } from '@/store';
let ConfiguracaoDeMensagem = class ConfiguracaoDeMensagem extends Vue {
    constructor() {
        super(...arguments);
        this.headers = [
            { text: 'Departamento', value: 'departamento_nome' },
            { text: 'Mensagem', value: 'syngoo_mensagem_name' },
            { text: 'Descrição', value: 'syngoo_mensagem_description' },
            { text: 'Envio manual', value: 'ativo_manual' },
            { text: 'Envio automático', value: 'ativo_automatico' },
        ];
        this.loading = false;
        this.items = [];
    }
    get syngooMensagemConfiguracaoItems() {
        return syngooMensagemConfiguracaoStore.syngooMensagemConfiguracao;
    }
    async change(item, tipo) {
        if (tipo == 1) {
            // @ts-ignore
            await syngooMensagemConfiguracaoStore.createSyngooMensagemConfiguracao({
                syngoo_mensagem_id: item.syngoo_mensagem_id,
                ativo_manual: item.ativo_manual,
            });
        }
        else {
            // @ts-ignore
            await syngooMensagemConfiguracaoStore.createSyngooMensagemConfiguracao({
                syngoo_mensagem_id: item.syngoo_mensagem_id,
                ativo_automatico: item.ativo_automatico,
            });
        }
    }
    async mounted() {
        this.loading = true;
        await syngooMensagemConfiguracaoStore.getSyngooMensagemConfiguracoes();
        this.loading = false;
    }
};
ConfiguracaoDeMensagem = __decorate([
    Component({
        components: {
            SyngooFaleConosco,
        },
    })
], ConfiguracaoDeMensagem);
export default ConfiguracaoDeMensagem;
